// Use this to write your custom SCSS

.logo-size{
	width: 70%;
}

.logo-size-footer{
	width: 20%;
}


.logo-size-signin{
	width: 35%;
}