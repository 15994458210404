@font-face {
  font-family: 'Mont';
  src: url('Mont-Light.woff2') format('woff2'),
    url('Mont-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;  
  font-display: block;
}

@font-face {
  font-family: 'Mont';
  src: url('Mont-Book.woff2') format('woff2'),
    url('Mont-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;  
  font-display: block;
}


@font-face {
  font-family: 'Mont';
  src: url('Mont-Regular.woff2') format('woff2'),
    url('Mont-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;  
  font-display: block;
}


@font-face {
  font-family: 'Mont';
  src: url('Mont-SemiBold.woff2') format('woff2'),
    url('Mont-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;  
  font-display: block;
}


@font-face {
  font-family: 'Mont';
  src: url('Mont-Bold.woff2') format('woff2'),
    url('Mont-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;  
  font-display: block;
}


@font-face {
  font-family: 'Mont';
  src: url('Mont-Heavy.woff2') format('woff2'),
    url('Mont-Heavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;  
  font-display: block;
}